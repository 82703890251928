import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'

import global_en from './translations/en/global.json'
import global_ua from './translations/ua/global.json'

import home_en from './translations/en/home.json'
import home_ua from './translations/ua/home.json'

import stories_en from './translations/en/stories.json'
import stories_ua from './translations/ua/stories.json'

import contact_en from './translations/en/contact.json'
import contact_ua from './translations/ua/contact.json'

import donate_en from './translations/en/donate.json'
import donate_ua from './translations/ua/donate.json'

import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

i18next.init({
  interpolation: {escapeValue: false},
  lng: 'en',
  resources: {
    en: {
      global: global_en,
      home: home_en,
      stories: stories_en,
      contact: contact_en,
      donate: donate_en
    },
    ua: {
      global: global_ua,
      home: home_ua,
      stories: stories_ua,
      contact: contact_ua,
      donate: donate_ua
    }
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
