import './story.scss'

import Header from '../../components/general/header/header'
import StoryMain from '../../components/story/storyMain/storyMain'
import Footer from '../../components/general/footer/footer'
import { useEffect } from 'react'

const Story = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header/>
            <StoryMain/>
            <Footer/>
        </>
    )
}

export default Story