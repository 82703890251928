import './header.scss'

import Donate from '../donate/donate'

import { Link } from 'react-router-dom'
import { FaFacebookSquare, FaInstagram, FaYoutube } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'

import Logo from '../../../images/logo.webp'

enum languages {
    EN = 'en',
    UA = 'ua'
}

const Header = () => {
    const [t, i18n] = useTranslation('global')
    const [ currentLang, setCurrentLang ] = useState<languages>(languages.EN)
    const [ sliderIsOpen, setSliderIsOpen ] = useState(false)
    const sliderRef = useRef<HTMLDivElement>(null)
    const burgerRef = useRef<HTMLDivElement>(null)

    const handleChangeLanguage = (lang: languages) => {
        i18n.changeLanguage(lang)
    }

    const toggleBurgerSlider = () => {
        setSliderIsOpen(!sliderIsOpen)

    }

    useEffect(() => {
        const slider = sliderRef.current
        const burger = burgerRef.current

        if(slider && burger){
            const burgerItemsList = Array.from(burger.getElementsByClassName('burger-item') as HTMLCollectionOf<HTMLElement>)

            if(sliderIsOpen){
                slider.style.width = '300px'


                burgerItemsList[0].style.transform = 'rotate(45deg)'
                burgerItemsList[0].style.top = '4px'
                burgerItemsList[1].style.display = 'none'
                burgerItemsList[2].style.transform = 'rotate(-45deg)'
                burgerItemsList[2].style.top = '-4px'
            }else{
                slider.style.width = '0'


                burgerItemsList[0].style.transform = ''
                burgerItemsList[0].style.top = ''
                burgerItemsList[1].style.display = ''
                burgerItemsList[2].style.transform = ''
                burgerItemsList[2].style.top = ''
            }
        }
    }, [sliderIsOpen])

    return (
        <div className="header-wrapper">
            <div className='header-container container'>
                <div className='header-section'>
                    <div className='header-logo-container'>
                        <Link to='/'>
                            <img src={Logo} />
                        </Link>
                    </div>
                    <div className='header-navigation-container desktop-header-nav'>
                        <Link to='/stories'>
                            {t('header.navBar.stories')}
                        </Link>
                        <Link to='/contact'>
                            {t('header.navBar.contact')}
                        </Link>
                    </div>
                </div>
                <div className='header-section desktop-header-nav'>
                    <div className="header-social-container">
                        <Link to='/'>
                            <FaFacebookSquare/>
                        </Link>
                        <Link to='/'>
                            <FaInstagram/>
                        </Link>
                        <Link to='/'>
                            <FaYoutube/>
                        </Link> 
                    </div>
                    <div className="header-lang-container">
                        <p
                            onClick={() => {
                                setCurrentLang(languages.UA)
                                handleChangeLanguage(languages.UA)
                            }}
                            style={{
                                color: currentLang === languages.UA ? '#1F88C9' : '#000'
                            }}
                        >Ua</p>
                        <p
                            onClick={() => {
                                setCurrentLang(languages.EN)    
                                handleChangeLanguage(languages.EN)
                            }}
                            style={{
                                color: currentLang === languages.EN ? '#1F88C9' : '#000'
                            }}
                        >En</p>
                    </div>
                    <div className="donate-button-container">
                        <Donate bgColor='#1F87C8' activeColor='#fff' color='#1F87C8' fontSize='18px' />
                    </div>
                </div>
                <div className="burger-nav-container">
                    <div className="burger-container" ref={burgerRef} onClick={toggleBurgerSlider}>
                        <div className="burger-item"></div>
                        <div className="burger-item"></div>
                        <div className="burger-item"></div>
                    </div>
                    <div className="burger-slider-wrapper" ref={sliderRef}>

                        <div className="burger-slider-container">
                            <div className="burger-slider-social-container">
                                <Link to='/'>
                                    <FaFacebookSquare/>
                                </Link>
                                <Link to='/'>
                                    <FaInstagram/>
                                </Link>
                                <Link to='/'>
                                    <FaYoutube/>
                                </Link> 
                            </div>
                            <div className='burger-slider-navigation-container'>
                                <Link to='/stories'>
                                    {t('header.navBar.stories')}
                                </Link>
                                <Link to='/contact'>
                                    {t('header.navBar.contact')}
                                </Link>
                            </div>
                            <div className="header-lang-container">
                                <p
                                    onClick={() => {
                                        setCurrentLang(languages.UA)
                                        handleChangeLanguage(languages.UA)
                                    }}
                                    style={{
                                        color: currentLang === languages.UA ? '#1F88C9' : '#000'
                                    }}
                                >Ua</p>
                                <p
                                    onClick={() => {
                                        setCurrentLang(languages.EN)    
                                        handleChangeLanguage(languages.EN)
                                    }}
                                    style={{
                                        color: currentLang === languages.EN ? '#1F88C9' : '#000'
                                    }}
                                >En</p>
                            </div>
                            <div className="donate-button-container">
                                <Donate bgColor='#1F87C8' activeColor='#fff' color='#1F87C8' fontSize='18px' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header