import './donateMain.scss'

import donateJSON from '../../translations/en/donate.json'
import { useTranslation } from 'react-i18next'

const DonateMain = () => {
    const [ t, i18n ] = useTranslation('donate')

    return (
        <div className="donate-main">
            <div className="donate-main-container container">
                <h2 className="donate-main-title">{t('title')}</h2>
                <p className="donate-main-subtitle">{t('subtitle')}</p>

                <div className="donate-main-content-container">
                    {
                        donateJSON.content.map((elem, index) => {
                            return (
                                <div className="donate-main-content-item-container">
                                   <h2 className="donate-main-content-item-title">{t(`content.${index}.title`)}</h2>
                                   <div className="donate-main-content-item-text-container">
                                        {
                                            elem.text.map((text, i) => {
                                                return <p className="donate-main-content-item-text">{t(`content.${index}.text.${i}`)}</p>
                                            })
                                        }
                                   </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default DonateMain