import './contact.scss'

import Header from '../../components/general/header/header'
import ContactMain from '../../components/contact/contactMain'
import Footer from '../../components/general/footer/footer'
import { useEffect } from 'react'

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header/>
            <ContactMain/>
            <Footer/>
        </>
    )
}

export default Contact