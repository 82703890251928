import './donate.scss'

import Header from '../../components/general/header/header'
import DonateMain from '../../components/donateMain/donateMain'
import Footer from '../../components/general/footer/footer'
import { useEffect } from 'react'

const Donate = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header/>
            <DonateMain/>
            <Footer/>
        </>
    )
}

export default Donate