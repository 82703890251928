import './footer.scss'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Logo from '../../../images/logo.webp'
import Donate from '../donate/donate'
import { FaFacebookSquare, FaInstagram, FaYoutube } from 'react-icons/fa'

const Footer = () => {
 
    const [t, i18n] = useTranslation('global')

    return (
        <div className="footer-wrapper">
            <div className="footer container">
                <div className="footer-content">
                    <div className="footer-nav">
                        <Link to='/stories'>
                            {t('header.navBar.stories')}
                        </Link>
                        <Link to='/contact'>
                            {t('header.navBar.contact')}
                        </Link>
                    </div>
                    <div className="footer-info">
                        <div className="footer-info-section">
                            <div className="footer-logo">
                                <img src={Logo} alt="" />
                            </div>
                            <div className="footer-donation">
                                <Donate bgColor='#fff' color='#fff' activeColor='#133A52' fontSize='18px'/>
                            </div>
                        </div>
                        <div className="footer-info-section">
                            <Link to='mailto:info@lighttheminds.org'>info@lighttheminds.org</Link>
                        </div>
                        <div className="footer-info-section">
                            <div className="footer-address">
                                <p>PO Box 1292</p>
                                <p>Fort Washington, PA 19034</p>
                            </div>
                            <div className="footer-social-container">
                                <Link to='/'>
                                    <FaFacebookSquare/>
                                </Link>
                                <Link to='/'>
                                    <FaInstagram/>
                                </Link>
                                <Link to='/'>
                                    <FaYoutube/>
                                </Link> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-final">
                    <p>2024 Light The Minds All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default Footer