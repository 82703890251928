import './home.scss'

import Header from '../../components/general/header/header'
import HomeBanner from '../../components/home/homeBanner/homeBanner'
import HomeStats from '../../components/home/homeStats/homeStats'
import HomeInfo from '../../components/home/homeInfo/homeInfo'
import StorySlider from '../../components/general/storySlider/storySlider'
import Footer from '../../components/general/footer/footer'
import { useEffect } from 'react'

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header/>
            <div className="home">
                <HomeBanner/>
                <div className="home-content-wrapper">
                    <div className="home-content container">
                        <HomeStats/>
                        <HomeInfo/>
                        <StorySlider/>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Home