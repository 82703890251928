import './stories.scss'

import Header from '../../components/general/header/header'
import Footer from '../../components/general/footer/footer'
import StoriesMain from '../../components/stories/storiesMain/storiesMain'
import { useEffect } from 'react'

const Stories = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header/>
            <StoriesMain/>
            <Footer/>
        </>
    )
}

export default Stories