import './App.scss';

import Router from './router';

function App() {
  return (
    <>
      <Router/>
    </>
  )
}

export default App;
